<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%; max-width: 420px;"
  >
    <div class="d-flex justify-center mt-4 mb-2">
      <img
        height="64"
        width="64"
        alt="BankID logo"
        src="https://static.kivra.com/assets/logo/bankid-dark.png"
      >
    </div>
    <div class="text-center text-h5 mt-8 mb-6">
      Logga in med Mobilt BankID
    </div>
    <v-tabs
      v-model="activeTab"
      dark
      color="white"
      background-color="transparent"
      style="height: 800px; margin-bottom: 1rem;"
    >
      <v-tab
        v-if="false"
        href="#qr-code"
      >
        QR-kod
      </v-tab>
      <v-tab href="#national-security-number">
        Personnummer
      </v-tab>
      <v-tab-item
        value="qr-code"
        class="py-6"
        style="max-width: 420px;"
      >
        <v-img
          class="mx-auto"
          src="https://via.placeholder.com/220"
          max-width="220"
        />
        <div class="text-h6 mt-3 mb-2">
          Gör så här
        </div>
        <ol class="mb-4">
          <li>Starta BankID-appen.</li>
          <li>Klicka på QR-ikonen i BankID-appen</li>
          <li>Tillåt att BankID-appen får använda din kamera</li>
          <li>Rikta kameran mot QR-koden som visas här på skärmen</li>
        </ol>
        <v-btn
          color="secondary"
          class="secondary--text d-flex mb-6"
          rounded
          outlined
          x-large
          depressed
          :to="{ name: 'accounts-login' }"
        >
          Avbryt inloggningen
        </v-btn>
      </v-tab-item>
      <v-tab-item
        value="national-security-number"
        class="py-6"
        style="max-width: 420px;"
      >
        <div class="d-flex flex-column">
          <template v-if="!isCollecting">
            <v-form
              ref="bankIdForm"
              v-model="bankidFormValid"
              class="d-flex flex-column"
              @submit.prevent="onSubmitNin"
            >
              <v-text-field
                id="nin-input"
                v-model="nin"
                class="mb-4"
                :label="$t('Pages.Auth.nin')"
                hint="GigPort använder Mobilt BankID för att verifiera din identitet"
                persistent-hint
                color="accent"
                type="tel"
                :rules="[rules.nin_required, rules.nin_length]"
                :maxlength="12"
                counter
                outlined
                rounded
                required
              />
              <v-btn
                id="login-bankid-btn"
                type="submit"
                class="mb-4"
                light
                rounded
                depressed
                x-large
              >
                <img
                  :src="bankidBlack"
                  alt="Happy Character in Black"
                  style="width: 40px; margin-right: 4px"
                >
                Mobilt BankID
              </v-btn>
              <v-btn
                color="secondary"
                class="secondary--text mb-6"
                rounded
                outlined
                x-large
                depressed
                :to="{ name: 'accounts-login' }"
              >
                Avbryt inloggningen
              </v-btn>
            </v-form>
          </template>
          <template v-if="isCollecting">
            <BankidCollecting
              :is-logo="false"
              :same-device-url="sameDeviceUrl"
              @cancel="onBankIDCancel()"
            />
          </template>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import bankidBlack from '@/assets/img/bankid_black.svg'
import { rules } from '@/config/validation-rules.js'
import BankidCollecting from './BankidCollecting.vue'
import { AUTH_LOGIN_START, AUTH_LOGIN_COLLECT } from '@/store/actions.type'
export default {
  name: 'BankidAuth',
  components: { BankidCollecting },
  data: () => ({
    activeTab: 'qr-code',
    rules,
    bankidBlack,
    bankidFormValid: true,
    orderRef: null,
    sameDeviceUrl: null,
    nin: '',
    isCollecting: false
  }),
  computed: {
    isSafariOniOS () {
      return /iP(ad|od|hone).*Safari/i.test(navigator.userAgent) && !(/Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent))
    },
    isChromeOnAndroid () {
      return /Android/i.test(navigator.userAgent) && /Chrome/i.test(navigator.userAgent)
    }
  },
  watch: {
    '$route.query.orderRef': function (newVal, oldVal) {
      if (newVal) {
        this.orderRef = this.$route.query.orderRef
        this.isCollecting = true
        this.collect(newVal)
      }
    }
  },
  beforeMount () {
    if (this.$route.query.orderRef) {
      this.isCollecting = true
      this.orderRef = this.$route.query.orderRef
      this.collect(this.orderRef)
    }
  },
  methods: {
    onSubmitNin () {
      if (this.$refs.bankIdForm.validate()) {
        this.authenticateWithNin()
        this.isCollecting = true
      }
    },
    isMobileDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /android|ipad|iphone|ipod/i.test(userAgent)
    },
    getLaunchUrlByDevice (autoStartToken, redirectUrl) {
      if (this.isSafariOniOS || this.isChromeOnAndroid || this.isMobileDevice()) {
        return 'bankid:///?autostarttoken=' + autoStartToken + '&redirect=' + encodeURIComponent(redirectUrl)
      } else {
        return 'https://app.bankid.com/?autostarttoken=' + autoStartToken + '&redirect=' + encodeURIComponent(redirectUrl)
      }
    },
    authenticateWithNin () {
      this.$store.dispatch(AUTH_LOGIN_START, { nationalIdentityNumber: this.nin }).then((res) => {
        let redirectUrl = window.location.href
        if (this.$route.query.redirect) {
          redirectUrl += '&orderRef=' + res.data.orderRef
        } else {
          redirectUrl += '?orderRef=' + res.data.orderRef
        }

        // Use the getLaunchUrlByDevice method to get the appropriate URL
        this.sameDeviceUrl = this.getLaunchUrlByDevice(res.data.autoStartToken, redirectUrl)
        console.log('this.sameDeviceUrl', this.sameDeviceUrl)
        if (this.isMobileDevice()) {
          // Open sameDeviceUrl in new tab, use setTimeout for iOS
          setTimeout(() => {
            window.open(this.sameDeviceUrl)
          })
        } else {
          // Only collect if not on mobile and it's the same tab
          this.collect(res.data.orderRef)
        }
      }, err => {
        if (err.status === 0 || err.status === 500) {
          this.createAlert(this.$t('Validation.bank_id_failed'), 'warning', 'error')
        } else {
          this.$root.$emit('errorMessage', err.response)
        }
      })
    },
    collect (orderReference) {
      let payload
      if (this.$route.name === 'auth-giggly') {
        payload = {
          orderRef: orderReference,
          externalCompanyToken: '55d163d3-d07b-4b52-aefc-d54f4aacf9c1'
        }
      } else {
        payload = {
          orderRef: orderReference
        }
      }
      // console.log('payload', payload)
      this.$store.dispatch(AUTH_LOGIN_COLLECT, payload).then(({ data }) => {
        // console.log('data', data)
        if (data.status === 'userNotFound') {
          this.$router.push({ name: 'accounts-login' })
          this.$root.$emit('errorMessage', {
            data: 'User not found'
          })
          throw new Error('User not found')
        }
        this.$router.push({ name: 'dashboard' })
      }).catch((err) => {
        console.error(err)
        this.windowModel = 1
        if (err.status === 403 && err.data.errorCode === '403E005') {
          // TODO: Replace createAlert with inline alerts
          // this.createAlert('Too many login attempts, please wait 30 seconds.', 'priority_high', 'warning')
        } else {
          this.$root.$emit('errorMessage', err.response)
          this.isCollecting = false
        }
      })
    },
    onBankIDCancel () {
      // Stop collecting and set isCollecting to false
      this.isCollecting = false
      if (this.orderRef) {
        this.$authHttp.post('cancel', { orderRef: this.orderRef }).then(() => {
        }, err => {
          console.error(err)
        })
      }
    }
  }
}
</script>

<style>
/* Add any custom styles here */
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>
