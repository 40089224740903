import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sv from '@/lang/sv'
import en from '@/lang/en'
import { authAxios, mainAxios } from '@/common/api.service'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'sv',
  fallbackLocale: 'sv',
  messages: {
    sv: {
      ...sv,
      $vuetify: {
        badge: 'Bricka',
        close: 'Stäng',
        dataIterator: {
          noResultsText: 'Inga poster funna',
          loadingText: 'Laddar data...'
        },
        input: {
          clear: 'Rensa'
        },
        dataTable: {
          itemsPerPageText: 'Rader per sida:',
          ariaLabel: {
            sortDescending: 'Sorterat fallande.',
            sortAscending: 'Sorterat stigande.',
            sortNone: 'Osorterat.',
            activateNone: 'Aktivera för att ta bort sortering.',
            activateDescending: 'Aktivera för sortering fallande.',
            activateAscending: 'Aktivera för sortering stigande.'
          },
          sortBy: 'Sortera efter'
        },
        dataFooter: {
          itemsPerPageText: 'Objekt per sida:',
          itemsPerPageAll: 'Alla',
          nextPage: 'Nästa sida',
          prevPage: 'Föregående sida',
          firstPage: 'Första sidan',
          lastPage: 'Sista sidan',
          pageText: '{0}-{1} av {2}'
        },
        datePicker: {
          itemsSelected: '{0} markerade',
          nextMonthAriaLabel: 'Nästa månad',
          nextYearAriaLabel: 'Nästa år',
          prevMonthAriaLabel: 'Förra månaden',
          prevYearAriaLabel: 'Förra året'
        },
        noDataText: 'Ingen data tillgänglig',
        carousel: {
          prev: 'Föregående vy',
          next: 'Nästa vy',
          ariaLabel: {
            delimiter: 'Carousel slide {0} of {1}'
          }
        },
        calendar: {
          moreEvents: '{0} fler'
        },
        fileInput: {
          counter: '{0} filer',
          counterSize: '{0} filer (av {1} totalt)'
        },
        timePicker: {
          am: 'AM',
          pm: 'PM'
        },
        pagination: {
          ariaLabel: {
            wrapper: 'Pagination Navigation',
            next: 'Nästa sida',
            previous: 'Föregående sida',
            page: 'Gå till sidan {0}',
            currentPage: 'Aktuell sida, sida {0}'
          }
        },
        rating: {
          ariaLabel: {
            icon: 'Rating {0} of {1}'
          }
        }
      }
    },
    en: {
      ...en,
      $vuetify: {
        badge: 'Badge',
        close: 'Close',
        dataIterator: {
          noResultsText: 'No matching records found',
          loadingText: 'Loading items...'
        },
        input: {
          clear: 'Clear'
        },
        dataTable: {
          itemsPerPageText: 'Rows per page:',
          ariaLabel: {
            sortDescending: 'Sorted descending.',
            sortAscending: 'Sorted ascending.',
            sortNone: 'Not sorted.',
            activateNone: 'Activate to remove sorting.',
            activateDescending: 'Activate to sort descending.',
            activateAscending: 'Activate to sort ascending.'
          },
          sortBy: 'Sort by'
        },
        dataFooter: {
          itemsPerPageText: 'Items per page:',
          itemsPerPageAll: 'All',
          nextPage: 'Next page',
          prevPage: 'Previous page',
          firstPage: 'First page',
          lastPage: 'Last page',
          pageText: '{0}-{1} of {2}'
        },
        datePicker: {
          itemsSelected: '{0} selected',
          nextMonthAriaLabel: 'Next month',
          nextYearAriaLabel: 'Next year',
          prevMonthAriaLabel: 'Previous month',
          prevYearAriaLabel: 'Previous year'
        },
        noDataText: 'No data available',
        carousel: {
          prev: 'Previous visual',
          next: 'Next visual',
          ariaLabel: {
            delimiter: 'Carousel slide {0} of {1}'
          }
        },
        calendar: {
          moreEvents: '{0} more'
        },
        fileInput: {
          counter: '{0} files',
          counterSize: '{0} files ({1} in total)'
        },
        timePicker: {
          am: 'AM',
          pm: 'PM'
        },
        pagination: {
          ariaLabel: {
            wrapper: 'Pagination Navigation',
            next: 'Next page',
            previous: 'Previous page',
            page: 'Goto Page {0}',
            currentPage: 'Current Page, Page {0}'
          }
        },
        rating: {
          ariaLabel: {
            icon: 'Rating {0} of {1}'
          }
        }
      }
    }
  }
})

const loadedLanguages = ['sv'] // Default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  mainAxios.defaults.headers.common['Accept-Language'] = lang
  authAxios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import('@/lang/' + lang).then(messages => {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}
