<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%"
  >
    <v-alert
      v-if="alert.visible"
      class="rounded-xl"
      transition="scale-transition"
      :type="alert.type"
      dark
    >
      {{ alert.text }}
    </v-alert>
    <template v-if="!isCollecting">
      <h2
        class="mb-6"
        :class="{ 'text-h6': $vuetify.breakpoint.smAndDown}"
      >
        <v-btn
          id="back-arrow"
          class="ma-1"
          rounded
          text
          outlined
          @click="$router.push({ name: 'accounts-login' })"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        Skapa konto
      </h2>

      <v-form
        ref="registerUserForm"
        v-model="registerUserFormValid"
        class="d-flex flex-column"
        @submit.prevent="onSubmitRegister"
      >
        <v-text-field
          id="nin-input"
          v-model="nin"
          class="mb-4"
          :label="$t('Pages.Auth.nin')"
          hint="GigPort använder Mobilt BankID för att verifiera din identitet"
          persistent-hint
          color="accent"
          type="tel"
          :rules="[rules.nin_required, rules.nin_length]"
          :maxlength="12"
          counter
          outlined
          rounded
          required
        />
        <v-text-field
          id="email-input"
          v-model="email"
          class="mb-4"
          :maxlength="50"
          :rules="[rules.email_required, rules.email]"
          color="accent"
          :label="$t('Pages.Profile.email')"
          hint="Notiser om nya uppdrag skickas hit"
          persistent-hint
          type="email"
          outlined
          rounded
          required
        />
        <v-text-field
          id="phone-input"
          v-model="phone"
          class="mb-4"
          :maxlength="13"
          color="accent"
          type="tel"
          hint="Hit blir du uppringd om du får ett uppdrag"
          persistent-hint
          :rules="[rules.phone]"
          outlined
          rounded
          :label="$t('Common.phone_number')"
        />
        <v-btn
          id="login-bankid-btn"
          type="submit"
          class="mb-4"
          light
          rounded
          depressed
          x-large
          :loading="isSubmitting"
          :disable="isSubmitting"
        >
          <img
            :src="bankidBlack"
            style="width: 40px; margin-right: 4px"
          >
          Slutför registreringen
        </v-btn>
      </v-form>
      <div class="text-subtitle-1 mb-2">
        Villkor
      </div>
      <div class="text-body-2 mb-2">
        När du registrerar dig för GigPort och accepterar våra Allmänna villkor
        intygar du även att du har fått tillgång till vår dataskyddsinformation.
      </div>
      <router-link
        :to="{ name: 'tos-users' }"
        target="_blank"
        rel="noreferrer"
        v-text="$t('Footer.terms')"
      />
      <router-link
        :to="{ name: 'privacy-policy' }"
        target="_blank"
        rel="noreferrer"
        v-text="$t('Footer.privacy')"
      />
    </template>
    <template v-if="isCollecting">
      <BankidCollecting
        :is-logo="false"
        :same-device-url="sameDeviceUrl"
        @cancel="onBankIDCancel()"
      />
    </template>
  </div>
</template>

<script>
import bankidBlack from '@/assets/img/bankid_black.svg'
import { rules } from '@/config/validation-rules.js'
import { AUTH_REGISTER_START, AUTH_REGISTER_COLLECT } from '@/store/actions.type'
import BankidCollecting from './BankidCollecting.vue'

export default {
  name: 'RegisterUser',
  components: {
    BankidCollecting
  },
  data: () => ({
    bankidBlack,
    registerUserFormValid: true,
    isCollecting: false,
    rules,
    nin: '',
    email: '',
    phone: '',
    alert: {
      visible: false,
      text: '',
      type: 'error'
    },
    isSubmitting: false,
    sameDeviceUrl: null
  }),
  computed: {
    isSafariOniOS () {
      return /iP(ad|od|hone).*Safari/i.test(navigator.userAgent) && !(/Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent))
    },
    isChromeOnAndroid () {
      return /Android/i.test(navigator.userAgent) && /Chrome/i.test(navigator.userAgent)
    }
  },
  watch: {
    '$route.query.orderRef': function (newVal, oldVal) {
      if (newVal) {
        this.orderRef = this.$route.query.orderRef
        this.isCollecting = true
        this.collect(newVal)
      }
    }
  },
  beforeMount () {
    if (this.$route.query.orderRef) {
      this.isCollecting = true
      this.orderRef = this.$route.query.orderRef
      this.collect(this.orderRef)
    }
  },
  mounted () {},
  beforeDestroy () {},
  methods: {
    isMobileDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /android|ipad|iphone|ipod/i.test(userAgent)
    },
    getLaunchUrlByDevice (autoStartToken, redirectUrl) {
      if (this.isSafariOniOS || this.isChromeOnAndroid || this.isMobileDevice()) {
        return 'bankid:///?autostarttoken=' + autoStartToken + '&redirect=' + encodeURIComponent(redirectUrl)
      } else {
        return 'https://app.bankid.com/?autostarttoken=' + autoStartToken + '&redirect=' + encodeURIComponent(redirectUrl)
      }
    },
    onSubmitRegister () {
      if (this.$refs.registerUserForm.validate()) {
        this.isSubmitting = true
        const registration = {
          terms: true,
          email: this.email,
          userType: 'consultant',
          nationalIdentityNumber: this.nin,
          phone: this.phone
        }
        this.registerWithNin(registration)
      }
    },
    onBankIDCancel () {
      // Stop collecting and set isCollecting to false
      this.isCollecting = false
      this.isSubmitting = false
      if (this.orderRef) {
        this.$authHttp.post('cancel', { orderRef: this.orderRef }).then(() => {
        }, err => {
          console.error(err)
        })
      }
    },
    registerWithNin (registration) {
      this.$store.dispatch(AUTH_REGISTER_START, registration).then((res) => {
        this.isCollecting = true
        let redirectUrl = window.location.href
        if (this.$route.query.redirect) {
          redirectUrl += '&orderRef=' + res.data.orderRef
        } else {
          redirectUrl += '?orderRef=' + res.data.orderRef
        }
        // this.sameDeviceUrl = 'bankid:///?autostarttoken=' + res.data.autoStartToken + '&redirect=' + encodeURIComponent(redirectUrl)
        // if (this.isMobileDevice()) {
        //   // Open sameDeviceUrl in new tab
        //   window.open(this.sameDeviceUrl, '_blank')
        // }
        // this.collect(res.data.orderRef)
        this.sameDeviceUrl = this.getLaunchUrlByDevice(res.data.autoStartToken, redirectUrl)
        if (this.isMobileDevice()) {
          // Open sameDeviceUrl in new tab, use setTimeout for iOS
          setTimeout(() => {
            window.open(this.sameDeviceUrl)
          })
        } else {
          // Only collect if not on mobile and it's the same tab
          this.collect(res.data.orderRef)
        }
      }, err => {
        if (err.status === 0 || err.status === 500) {
          // console.log('Error: ', err)
          // this.createAlert('BankID authentication failed, Please try again', 'warning', 'error')
        } else {
          this.$root.$emit('errorMessage', err.response)
        }
        this.isSubmitting = false
        if (err.response.data.errorCode === '409E002') {
          this.alert.text = this.$t('Validation.email_in_use')
          this.alert.visible = true
        }
        if (err.response.data.errorCode === '409E003') {
          this.alert.text = this.$t('Validation.account_with_nin')
          this.alert.visible = true
        }
      })
    },
    collect (orderReference) {
      const payload = {
        orderRef: orderReference
      }
      this.$store.dispatch(AUTH_REGISTER_COLLECT, payload).then(({ data }) => {
        this.$router.push({ name: 'dashboard' })
      }).catch((err) => {
        console.error(err)
        this.windowModel = 1
        if (err.status === 403 && err.data.errorCode === '403E005') {
          // TODO: Replace createAlert with inline alerts
          // this.createAlert('Too many login attempts, please wait 30 seconds.', 'priority_high', 'warning')
        } else {
          this.$root.$emit('errorMessage', err.response)
        }
      })
    }
  }
}
</script>
