import Vue from 'vue'
import Vuex from 'vuex'
import { ApiService } from '@/common/api.service'
import JwtService from '@/common/jwt.service'
import createPersistedState from 'vuex-persistedstate'
import {
  GET_PROFILE,
  ADD_PAYMENT_CARD,
  AUTH_LOGIN_START,
  CHANGE_PAYMENT_DETAILS,
  CHANGE_PICTURE,
  CHECK_ACTIVE_SUBSCRIPTION,
  CHECK_AUTH,
  DELETE_ACCOUNT,
  LOGOUT,
  VERIFY_EMAIL,
  GET_PROFILE_BY_ID,
  GET_USER,
  EDIT_USER,
  CHANGE_EMAIL,
  RESEND_EMAIL,
  CHANGE_SEARCHABLE,
  CLEAR_SEARCH_FILTERS,
  UPDATE_COMPLETION,
  GET_COMPANIES,
  GET_CONTRACTS,
  GET_COMPANY_MEMBERS,
  GET_COMPANY_INVITE_LINK,
  SAVE_COMPANY_DETAILS,
  GET_COMPANY_DETAILS,
  UPDATE_SCROLL_POSITION,
  GET_PRODUCTS,
  AUTH_LOGIN_COLLECT,
  AUTH_REGISTER_START,
  AUTH_REGISTER_COLLECT,
  AUTH_REGISTER,
  UPDATE_SEARCH_FILTERS
} from './actions.type.js'

import {
  LOADING,
  PURGE_AUTH,
  RESET_STATE,
  SET_AUTH,
  SET_COMPANIES,
  SET_COMPANY_DETAILS,
  SET_COMPANY_INVITE_LINK,
  SET_COMPANY_MEMBERS,
  SET_COMPLETION,
  SET_CONTRACTS,
  SET_EMAIL,
  SET_ERROR,
  SET_LOCATIONS,
  SET_PHONE,
  SET_PICTURE,
  SET_PRODUCTS,
  SET_PROFILE,
  SET_PUBLIC_PROFILE,
  SET_USER,
  SET_SCROLL_POSITION,
  SET_SEARCH_FILTERS
} from './mutations.type'

Vue.use(Vuex)

const settingsInitialState = {
  platform: {
    isSearchable: true
  }
}

const getUserInitialState = () => {
  return {
    firstName: '',
    email: '',
    id: '',
    lastName: '',
    phone: '',
    picture: {
      key: '',
      url: ''
    },
    nationalIdentityNumber: null,
    verified: false,
    isAdmin: false,
    isPremium: false,
    settings: settingsInitialState,
    terms: {
      accepted: false
    }
  }
}
const getInitialSearchFilters = () => ({
  searchTerm: '',
  languages: [],
  personality: '',
  educationLevel: '',
  locations: []
})

const getInitialState = () => ({
  isAuthenticated: !!JwtService.getToken(),
  isAdmin: false,
  isPremium: false,
  isLoading: false,
  paymentDetails: {},
  phone: null,
  profile: {},
  publicProfile: {},
  searchScrollPosition: 0,
  refCount: 0,
  searchFilters: getInitialSearchFilters(),
  subscriptionPurchase: null,
  user: getUserInitialState(),
  brandName: 'GigPort',
  adminForCompanies: [],
  companyMembers: [],
  companyInviteLink: '',
  contracts: [],
  products: []
})

export const state = { ...getInitialState() }

const actions = {
  [GET_USER] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getUser(function (err, res) {
        if (err) {
          reject(err)
          context.commit(SET_ERROR, err.response)
        } else {
          context.commit(SET_USER, res.data.user)
          resolve(res)
        }
      })
    })
  },
  [CHECK_AUTH] (context) {
    const token = JwtService.getToken()
    if (token) {
      return JwtService.checkTokenExpired(function (err, res) {
        if (err) {
          return Promise.reject(err)
        }
        if (res && res.tokenExpired === true) {
          context.commit(RESET_STATE)
          context.commit(PURGE_AUTH)
          // TODO send to login with "expired" message
          return Promise.resolve(res)
        }
        ApiService.setHeader()
        return new Promise((resolve, reject) => {
          ApiService.getUser(function (err, res) {
            if (err) {
              reject(err)
              context.commit(SET_ERROR, err.response)
              context.commit(RESET_STATE)
              context.commit(PURGE_AUTH)
            } else {
              context.commit(SET_USER, res.data.user)
              resolve(res)
            }
          })
        })
      })
    } else {
      context.commit(RESET_STATE)
      context.commit(PURGE_AUTH)
    }
  },
  [VERIFY_EMAIL] (context, token) {
    return new Promise((resolve, reject) => {
      ApiService.verifyEmailLogin(token, function (err, res) {
        if (err) reject(err)
        else {
          context.commit('setVerified')
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [CHANGE_SEARCHABLE] (context, isSearchable) {
    return new Promise((resolve, reject) => {
      ApiService.updateSearchable(!isSearchable, function (err, res) {
        if (err) reject(err)
        else {
          context.commit('setHidden', res.data.profile.hidden)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [AUTH_REGISTER] (context, nin) {
    return new Promise((resolve, reject) => {
      ApiService.authRegister(nin, function (err, res) {
        if (err) reject(err)
        else {
          context.dispatch(AUTH_REGISTER_START)
          resolve(res)
        }
      })
    })
  },
  [AUTH_REGISTER_START] (context, nin) {
    return new Promise((resolve, reject) => {
      ApiService.authRegisterStart(nin, function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [AUTH_REGISTER_COLLECT] (context, orderRef) {
    return new Promise((resolve, reject) => {
      function collect () {
        ApiService.authRegisterCollect(orderRef, function (err, res) {
          if (err) {
            reject(err)
          } else if (res.data.status === 'complete') {
            JwtService.saveToken(res.data.token)
            context.commit(SET_AUTH)
            context.dispatch(GET_USER).then((res) => {
              resolve(res)
            }).catch((err) => {
              reject(err)
            })
          } else {
            setTimeout(collect, 1000)
          }
        })
      }
      collect()
    })
  },
  [AUTH_LOGIN_START] (context, nin) {
    return new Promise((resolve, reject) => {
      ApiService.authLoginStart(nin, function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [AUTH_LOGIN_COLLECT] (context, orderRef) {
    return new Promise((resolve, reject) => {
      function collect () {
        ApiService.authLoginCollect(orderRef, function (err, res) {
          if (err) {
            reject(err)
          } else if (res.data.status === 'complete') {
            JwtService.saveToken(res.data.authorization)
            context.commit(SET_AUTH)
            context.dispatch(GET_USER).then((res) => {
              resolve(res)
            }).catch((err) => {
              reject(err)
            })
          } else if (res.data.status === 'userNotFound') {
            resolve(res)
          } else {
            setTimeout(collect, 1000)
          }
        })
      }
      collect()
    })
  },
  [GET_COMPANY_INVITE_LINK] (context, companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanyInviteLink(companyId, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_INVITE_LINK, res.data.link)
          resolve(res)
        }
      })
    })
  },
  [GET_COMPANY_MEMBERS] (context, companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanyMembersById(companyId, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_MEMBERS, res.data.users)
          resolve(res)
        }
      })
    })
  },
  [GET_PRODUCTS] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getProducts(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PRODUCTS, res.data.products)
          resolve(res)
        }
      })
    })
  },
  [GET_COMPANIES] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanies(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANIES, res.data)
          resolve(res)
        }
      })
    })
  },
  [GET_CONTRACTS] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getContracts(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_CONTRACTS, res.data.contracts)
          resolve(res)
        }
      })
    })
  },
  [GET_PROFILE] (context) {
    return new Promise((resolve, reject) => {
      ApiService.getProfile(function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PROFILE, res.data.profile)
          resolve(res)
        }
      })
    })
  },
  [GET_PROFILE_BY_ID] (context, id) {
    return new Promise((resolve, reject) => {
      ApiService.getProfileById(id, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PUBLIC_PROFILE, res.data.profile)
          resolve(res)
        }
      })
    })
  },
  [SET_LOCATIONS] (context, locations) {
    context.commit(SET_LOCATIONS, locations)
  },
  [CHECK_ACTIVE_SUBSCRIPTION] (context) {
    return new Promise((resolve, reject) => {
      ApiService.checkhasActiveSubscription(function (err, res) {
        if (err) reject(err)
        else {
          context.commit('setHasActiveSubscription', res.data)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [LOGOUT] (context) {
    context.commit(RESET_STATE)
    context.commit(PURGE_AUTH)
  },
  [CLEAR_SEARCH_FILTERS] (context) {
    context.commit(CLEAR_SEARCH_FILTERS)
  },
  [UPDATE_SEARCH_FILTERS] (context, searchFilters) {
    context.commit(SET_SEARCH_FILTERS, searchFilters)
  },
  [EDIT_USER] (context, user) {
    return new Promise((resolve, reject) => {
      ApiService.editUser(user, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_PHONE, user)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [SAVE_COMPANY_DETAILS] (context, companyDetails) {
    return new Promise((resolve, reject) => {
      ApiService.saveCompanyDetails(companyDetails, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_DETAILS, companyDetails)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [GET_COMPANY_DETAILS] (context, companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getCompanyDetails(companyId, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_COMPANY_DETAILS, res.data.company)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [CHANGE_EMAIL] (context, emailData) {
    return new Promise((resolve, reject) => {
      ApiService.changeEmail(emailData, function (err, res) {
        if (err) reject(err)
        else {
          context.commit(SET_EMAIL, emailData)
          setTimeout(function () {
            resolve(res)
          }, 100)
        }
      })
    })
  },
  [RESEND_EMAIL] (context) {
    return new Promise((resolve, reject) => {
      ApiService.resendEmail(function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [ADD_PAYMENT_CARD] (context, token) {
    return new Promise((resolve, reject) => {
      ApiService.addPaymentCard(token, (err, data) => {
        if (err) reject(err)
        else {
          resolve(data)
        }
      })
    })
  },
  [CHANGE_PAYMENT_DETAILS] (context, token) {
    return new Promise((resolve, reject) => {
      ApiService.setPaymentDetails(token, (err, data) => {
        if (err) reject(err)
        else {
          resolve(data)
        }
      })
    })
  },
  [DELETE_ACCOUNT] (context, confirmation) {
    return new Promise((resolve, reject) => {
      ApiService.removeAccount(confirmation, function (err, res) {
        if (err) reject(err)
        else {
          resolve(res)
        }
      })
    })
  },
  [CHANGE_PICTURE] (context, profile) {
    context.commit(SET_PICTURE, profile.picture.url)
    context.commit(SET_COMPLETION, profile.completion)
  },
  [UPDATE_COMPLETION] (context, completion) {
    context.commit(SET_COMPLETION, completion)
  },
  [UPDATE_SCROLL_POSITION] (context, position) {
    context.commit(SET_SCROLL_POSITION, position)
  }
}

const mutations = {
  [SET_SCROLL_POSITION] (state, position) {
    state.searchScrollPosition = position
  },
  [SET_COMPLETION] (state, { completion }) {
    state.profile.completion = completion
  },
  [LOADING] (state, isLoading) {
    if (isLoading) {
      state.refCount++
      state.isLoading = true
    } else if (state.refCount > 0) {
      state.refCount--
      state.isLoading = (state.refCount > 0)
    }
  },
  [CLEAR_SEARCH_FILTERS] (state) {
    Object.assign(state.searchFilters, getInitialSearchFilters())
  },
  setVerified (state) {
    state.user.verified = true
  },
  setHidden (state, hidden) {
    state.profile.hidden = hidden
  },
  setHasActiveSubscription (state, res) {
    state.user.isPremium = res.isPremium
    state.user.isAdmin = res.isAdmin
  },
  updatePaymentDetails (state, details) {
    state.paymentDetails = details
  },
  updateSubscription (state, bool) {
    state.isPremium = bool
  },
  updatePurchaseSubscription (state, subscription) {
    state.subscriptionPurchase = subscription
  },
  [SET_PICTURE] (state, picture) {
    state.user.picture.url = picture
    state.profile.picture.url = picture
  },
  [SET_PHONE] (state, user) {
    state.phone = user.phone
  },
  [SET_COMPANY_DETAILS] (state, companyDetails) {
    state.companyDetails = companyDetails
  },
  [SET_COMPANY_MEMBERS] (state, members) {
    state.companyMembers = members
  },
  [SET_COMPANY_INVITE_LINK] (state, link) {
    state.companyInviteLink = link
  },
  [SET_CONTRACTS] (state, data) {
    state.contracts = data
  },
  [SET_PRODUCTS] (state, products) {
    state.products = products
  },
  [SET_COMPANIES] (state, data) {
    state.adminForCompanies = data.adminForCompanies
    state.memberInCompanies = data.memberInCompanies
  },
  [SET_PROFILE] (state, profile) {
    if (Object.keys(profile).length !== 0) {
      state.profile = profile
    }
  },
  [SET_PUBLIC_PROFILE] (state, profile) {
    state.publicProfile = profile
  },
  [SET_AUTH] (state) {
    ApiService.setHeader()
    state.isAuthenticated = true
  },
  [SET_USER] (state, user) {
    state.isAdmin = user?.subscriptions?.isAdmin
    state.isPremium = user?.subscriptions?.isPremium
    state.user = user
  },
  [PURGE_AUTH] (state) {
    JwtService.destroyToken()
    ApiService.deleteHeader()
    state.isAuthenticated = false
  },
  [RESET_STATE] (state) {
    Object.assign(state, getInitialState())
  },
  [SET_ERROR] (state, error) {
    state.errors = error
  },
  [SET_SEARCH_FILTERS] (state, searchFilters) {
    state.searchFilters = searchFilters
  }
}

const getters = {}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  plugins: [createPersistedState()]
})
