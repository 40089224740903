const userHandler = {
  firstName (user) {
    if (user.firstName) return user.firstName
    else return 'n/a'
  },
  lastName (user) {
    if (user.lastName) return user.lastName
    else return 'n/a'
  },
  email (user) {
    if (user.email) return user.email
    else return 'n/a'
  },
  profilePicture (user) {
    if (user.picture && user.picture.url) return user.picture.url
    else return '../assets/img/defaultAvatar.png'
  }
}

export { userHandler }
