<template>
  <div class="animate__animated animate__fadeIn">
    <div
      v-if="isLogo"
      class="d-flex justify-center mt-8 mb-6"
    >
      <img
        height="64"
        width="64"
        alt="BankID logo"
        src="https://static.kivra.com/assets/logo/bankid-dark.png"
      >
    </div>
    <div class="d-flex flex-column">
      <div class="text-center text-h5 mb-4">
        Starta BankID-appen...
      </div>
      <p>
        För att kunna registrera dig behöver du öppna Mobilt BankID på den enhet
        som är kopplad till ditt angivna personnummer
      </p>
      <v-progress-circular
        class="mx-auto mt-4 mb-8"
        :size="64"
        :width="5"
        color="accent"
        indeterminate
      />
      <div
        v-if="$vuetify.breakpoint.mobile"
        class="text-center mb-4"
      >
        <a
          :href="sameDeviceUrl"
          :disabled="!sameDeviceUrl"
          :loading="!sameDeviceUrl"
          v-text="$t('Pages.Auth.bankid_open')"
        />
      </div>
      <v-btn
        color="secondary"
        class="secondary--text d-flex mb-6"
        rounded
        outlined
        x-large
        depressed
        @click="onCancel()"
      >
        Avbryt
      </v-btn>
    </div>
  </div>
</template>

<script>
import bankidBlack from '@/assets/img/bankid_black.svg'
import { rules } from '@/config/validation-rules.js'
export default {
  name: 'BankidCollecting',
  components: {},
  props: {
    isLogo: {
      type: Boolean,
      default: false
    },
    sameDeviceUrl: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    activeTab: 'qr-code',
    rules,
    bankidBlack,
    nin: ''
  }),
  methods: {
    onCancel () {
      // Emit cancel message to parent component
      this.$emit('cancel')
    }
  }
}
</script>

<style>
/* Add any custom styles here */
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>
