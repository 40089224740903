import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { i18n } from './i18n'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        accent: '#f57c00',
        anchor: '#f57c00',
        error: '#d32f2f',
        info: '#1976d2',
        primary: '#00a7da',
        secondary: '#1a2934',
        success: '#388e3c',
        warning: '#f57c00',
        // custom variables below
        background: '#fff',
        bleu: '#265B76',
        bleuDarken: '#1f4a61',
        blueLighten: '#2c6a89',
        deleteAction: '#5d6971',
        neutral: '#f0f0f0',
        secondAccent: '#dd552e'
      },
      dark: {
        accent: '#ffb74d',
        anchor: '#ffb74d',
        error: '#e57373',
        info: '#64b5f6',
        primary: '#00a7da',
        secondary: '#fff',
        success: '#81c784',
        warning: '#ffb74d',
        // custom variables below
        background: '#0a1b25',
        bleu: '#265B76',
        bleuDarken: '#1f4a61',
        blueLighten: '#2c6a89',
        deleteAction: '#5d6971',
        neutral: '#1a3d4f',
        secondAccent: '#E38367'
      }
    },
    dark: true,
    options: {
      customProperties: true
    }
  }
})
