// Packages
import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Language - I18n
import { i18n, loadLanguageAsync } from './plugins/i18n'

// Vue-Meta

// Analytics
import VueGtm from '@gtm-support/vue2-gtm'

// Styles
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import '@/assets/scss/main.scss'
import 'animate.css'
import { CHECK_AUTH } from './store/actions.type'
import { ApiService } from './common/api.service'

Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

const isProd = process.env.NODE_ENV === 'production'

Vue.config.productionTip = false
Vue.config.debug = !isProd

ApiService.init()

Vue.use(Croppa)

router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  const userIsAuthenticated = store.state.isAuthenticated
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  loadLanguageAsync(lang).then(() => {
    store.dispatch(CHECK_AUTH).then(() => {
      if (requiresAuth && !userIsAuthenticated) {
        next({ name: 'accounts-login', params: { lang: i18n.locale }, query: {} })
      } else if (userIsAuthenticated && to.name === 'accounts-login') {
        // Redirect to dashboard if the authenticated user tries to visit the login page
        next({ name: 'dashboard', params: { lang: i18n.locale }, query: {} })
      } else {
        next()
      }
    })
  })
})

Vue.use(VueGtm, {
  id: 'GTM-N8QSC4G',
  debug: !isProd,
  vueRouter: router
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
