<template>
  <v-alert
    class="t-alert"
    transition="scale-transition"
    :type="type"
    :icon="icon"
    dark
  >
    <template v-if="message">
      <template v-if="message.error">
        {{ message ? message : message.error }}
      </template>
      <template v-else>
        {{ message }}
      </template>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: 'CustomAlert',
  props: {
    message: {
      type: String,
      default: 'Something went wrong'
    },
    type: {
      type: String,
      default: 'error'
    },
    icon: {
      type: [Boolean, String],
      default: false
    }
  }
}
</script>
