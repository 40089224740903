// import store from '@/store'
var innerRoutes = {
  pages: [
    {
      path: '',
      redirect: 'dashboard'
    },
    {
      path: 'dashboard',
      name: 'dashboard',
      component: () => import(
        '@/pages/dashboard/Dashboard.vue'
      ),
      meta: {
        requiresAuth: true
      }
    },
    // {
    //   path: 'products',
    //   name: 'products',
    //   component: () => import(
    //     /* webpackChunkName: "inner" */
    //     '@/pages/products/Products.vue'
    //   ),
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    {
      path: 'search',
      name: 'inner-search',
      component: () => import(
        '@/pages/search/Search.vue'
      ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'offers',
      name: 'inner-offers',
      component: () => import(
        '@/pages/offers/InnerOffers.vue'
      ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'profile/:id',
      name: 'inner-profile',
      component: () => import(
        '@/pages/profile/public/ProfilePublic.vue'
      )
    },
    {
      path: 'agreements',
      name: 'agreements',
      component: () => import(
        '@/pages/agreements/Agreements.vue'
      ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'time',
      name: 'time',
      component: () => import(
        '@/pages/time/Time.vue'
      ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'company/',
      name: 'company',
      component: () => import(
        '@/pages/company/Company.vue'
      ),
      meta: {
        requiresAuth: true
      },
      redirect: {
        name: 'company-members'
      },
      children: [
        {
          path: '',
          redirect: { name: 'company-members' }
        },
        {
          path: 'details',
          name: 'company-details',
          component: () => import(
            '@/pages/company/CompanyDetails.vue'
          )
        },
        {
          path: 'members',
          name: 'company-members',
          component: () => import(
            '@/pages/company/CompanyMembers.vue'
          )
        },
        {
          path: 'projects',
          name: 'company-projects',
          component: () => import(
            '@/pages/company/CompanyProjects.vue'
          )
        }
      ]
    },
    {
      path: 'contracts',
      name: 'contracts',
      component: () => import(
        '@/pages/contracts/Contracts.vue'
      )
    },
    {
      path: 'pricing',
      name: 'pricing',
      component: () => import(
        '@/pages/outer/Pricing.vue'
      )
    },
    {
      path: 'settings/',
      component: () => import(
        '@/pages/settings/Settings.vue'
      ),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'settings',
          component: () => import(
            '@/pages/settings/components/SettingsAccount.vue'
          )
        },
        {
          path: 'options',
          name: 'settings-mobile',
          component: () => import(
            '@/pages/settings/components/SettingsMobile.vue'
          )
        },
        {
          path: 'privacy',
          name: 'privacy',
          component: () => import(
            '@/pages/settings/components/SettingsPrivacy.vue'
          )
        }
      ]
    },
    {
      path: 'settings/export',
      name: 'settings-export',
      component: () => import(
        '@/pages/settings/SettingsExport.vue'
      ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'user/billing',
      component: () => import(
        '@/pages/billing/Billing.vue'
      ),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'user-billing',
          component: () => import(
            '@/pages/billing/components/BillingComponent.vue'
          )
        },
        {
          path: ':id',
          name: 'user-bill',
          component: () => import(
            '@/pages/billing/components/BillComponent.vue'
          )
        }
      ]
    },
    {
      path: 'edit-profile',
      name: 'edit-profile',
      component: () => import(
        '@/pages/profile/edit/ProfileEdit.vue'
      ),
      meta: {
        requiresAuth: true
      }
    }
  ]
}

export default innerRoutes
