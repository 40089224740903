<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-progress-circular
        :size="100"
        :width="5"
        color="accent"
        indeterminate
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageLoader'
}
</script>
