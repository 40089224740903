<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%"
  >
    <h2 class="mb-6">
      Logga in
    </h2>
    <v-btn
      id="login-bankid-btn"
      type="submit"
      class="mb-6"
      light
      rounded
      depressed
      x-large
      :to="{ name: 'bankid-auth' }"
    >
      <img
        :src="bankidBlack"
        alt="Happy Character in Black"
        style="width: 40px; margin-right: 4px"
      >
      Mobilt BankID
    </v-btn>
    <v-btn
      color="secondary"
      class="secondary--text mb-6"
      rounded
      outlined
      x-large
      depressed
      :to="{ name: 'register-user' }"
    >
      Skapa konto
    </v-btn>
    <a
      href="https://gigport.se/mobilt-bankid-dator/"
      target="_blank"
      rel="noopener"
    >Så loggar du in med BankID</a>
  </div>
</template>

<script>
import bankidBlack from '@/assets/img/bankid_black.svg'

export default {
  name: 'LoginView',
  components: {},
  data: () => ({
    bankidBlack
  }),
  computed: {},
  watch: {},
  mounted () {},
  beforeDestroy () {},
  methods: {}
}
</script>
