<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-progress-circular
        v-show="isLoading"
        :size="100"
        :width="5"
        color="accent"
        indeterminate
      />
    </v-row>
  </v-container>
</template>

<script>
import { VERIFY_EMAIL } from '@/store/actions.type'
import { mapState } from 'vuex'

export default {
  name: 'VerifyEmail',
  props: {
    createAlert: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState(['isAuthenticated'])
  },
  beforeMount () {
    this.verifyEmail()
  },
  methods: {
    verifyEmail () {
      this.isLoading = true
      const token = this.$route.query.token // Fetch token from route query.
      this.$store
        .dispatch(VERIFY_EMAIL, token)
        .then(
          () => {
            this.$root.$emit(
              'successMessage',
              'Din e-postadress har verifierats. Du kommer att bli omdirigerad till startsidan om 2 sekunder.'
            )
            setTimeout(() => {
              if (this.isAuthenticated) {
                this.$router.push({ name: 'dashboard' })
              } else {
                this.$router.push({ name: 'accounts-login' })
              }
            }, 2000)
            this.isLoading = false
          },
          (err) => {
            console.error(err)
            this.$router.push({ name: 'accounts-login' })
            // console.log('in error verify email', err)
            this.$root.$emit('errorMessage', err.response)
            this.isLoading = false
          }
        )
        .catch((err) => {
          console.error(err)
          // console.log('in error 2verify email', err)
          this.$router.push({ name: 'accounts-login' })
          this.$root.$emit('errorMessage', err.response)
          this.isLoading = false
        })
    }
  }
}
</script>
