<template>
  <div class="text-center d-flex animate__animated animate__fadeIn">
    <router-link
      v-if="logomark"
      id="logomark"
      :to="isAuthenticated ? { name: 'dashboard' } : { name: 'accounts-login' }"
      class="logomark-height"
    >
      <img
        src="@/assets/img/logomark-white.svg"
        class="logomark"
        height="36"
        width="36"
        type="image/svg+xml"
        alt="White GigPort Logomark"
      >
    </router-link>
    <router-link
      v-if="logotype"
      id="logotype"
      :to="isAuthenticated ? { name: 'dashboard' } : { name: 'accounts-login' }"
      class="logotype-height mr-3"
    >
      <img
        src="@/assets/img/logotype-white.svg"
        class="logotype"
        height="36"
        width="150"
        type="image/svg+xml"
        alt="White GigPort Logotype"
      >
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LogoGroup',
  props: {
    logomark: { type: Boolean, default: false },
    logotype: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(['isAuthenticated'])
  }
}
</script>

<style lang="css" scoped>
.logotype {
  width: auto;
  cursor: pointer;
  height: 36px;
  margin-left: 0.5rem;
}

.logotype-height {
  height: 36px;
}

.logomark {
  width: 36px;
  height: 36px;
}

.logomark-height {
  height: 36px;
}
</style>
