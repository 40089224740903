import { i18n } from '@/plugins/i18n'

var rules = {
  shortDate: (value) => {
    const pattern = /^([12]\d{3}-(0[1-9]|1[0-2]))$/
    return pattern.test(value) || i18n.tc('Validation.short_date')
  },
  longDate: (value) => {
    const pattern = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
    return pattern.test(value) || i18n.tc('Validation.long_date')
  },
  website: (value) => {
    const pattern = /^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)?$/ // eslint-disable-line
    return (value === null || value === undefined || value.length === 0 || (value && pattern.test(value))) || i18n.tc('Validation.website')
  },
  organisationNumber: (value) => {
    const pattern = /^(\d{6})(\d{2})?-?(\d{4})$/
    return pattern.test(value) || i18n.tc('Validation.organisation_number')
  },
  VATNumber: (value) => {
    const pattern = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?(([0-9]{12})|([0-9]{14}))|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/
    return pattern.test(value) || i18n.tc('Validation.vat_number')
  },
  // Support international names with super sweet unicode
  name_input: (value) => {
    const pattern = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u // eslint-disable-line
    return pattern.test(value) || i18n.tc('Validation.name_input')
  },
  email: (value) => /^([a-z0-9_.+-]+@[\da-z.-]+\.[a-z.]{2,6})$/.test(value) || i18n.tc('Validation.email'),
  required: (value) => !!value || i18n.tc('Validation.required'),
  email_required: (value) => !!value || i18n.tc('Validation.email_required'),
  emails_required: (value) => !!value || i18n.tc('Validation.emails_required'),
  password_required: (value) => !!value || i18n.tc('Validation.password_required'),
  firstName_required: (value) => !!value || i18n.tc('Validation.firstName_required'),
  lastName_required: (value) => !!value || i18n.tc('Validation.lastName_required'),
  nin_length: (value) => (value.length === 12) || i18n.tc('Validation.nin_length'),
  nin_required: (value) => !!value || i18n.tc('Validation.nin_required'),
  phone: (value) => /\+?([\d|(][h|(\d{3})|.|\-|]{4,}\d)/.test(value) || i18n.tc('Validation.phone'),
  number: (value) => /^[0-9]*$/.test(value) || i18n.tc('Validation.number'),
  password: (value) => (value && value.length > 7) || i18n.tc('Validation.password'),
  spaces_password: (value) => !(/\s/.test(value)) || i18n.tc('Validation.spaces_password'),
  check: (value) => !!value || i18n.tc('Validation.check'),
  fee: (value) => (value) <= 100000 || i18n.tc('Validation.fee'),
  overtime: (value) => (value) <= 200000 || i18n.tc('Validation.overtime'),
  day: (value) => (value && value <= 24) || i18n.tc('Validation.day'),
  month: (value) => (value && value <= 744) || i18n.tc('Validation.month'),
  only_spaces: (value) => (value === undefined || value.length === 0 || (value && value.replace(/^\s+|\s+$/gm, '').length > 0)) || i18n.tc('Validation.only_spaces'),
  isMobile: () => {
    var device = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile)/i)
    return (device && device.length > 0) || false
  }
}
export { rules }
